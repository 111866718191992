<template>
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                Promotion calls
              </h2>
            </div>
          </div>
        </div>
        <div
          class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none"
        >
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Add Senior call"
              :href="$router.resolve({ name: 'admin.calls.promotion.create', query: { actAs: $route.query.actAs } }).href"
              target="_blank"
            ><i
              data-feather="plus"
            /></a>
            <a
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Senior call settings"
            ><i
              data-feather="settings"
            /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <div
              v-if="active && active.id"
              class="card card--senior-call-header"
            >
              <div
                class="card-body d-flex align-items-center justify-content-between"
              >
                <div>
                  <div class="row">
                    <div class="col-2 col-sm-2">
                      <b-dropdown
                        variant="link"
                        no-caret
                        toggle-class="p-0"
                        left
                      >
                        <template #button-content>
                          <i data-feather="more-vertical" />
                        </template>
                        <div
                          class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                        >
                          <b-dropdown-item
                            :href="$router.resolve({ name: 'admin.calls.promotion.view', params: { id: active.id }, query: { actAs: $route.query.actAs } }).href"
                            target="_blank"
                          >
                            <i
                              class="me-50"
                              data-feather="eye"
                            /> View promotion
                            call
                          </b-dropdown-item>
                          <div class="dropdown-divider" />
                          <b-dropdown-item
                            :href="$router.resolve({ name: 'admin.calls.promotion.groups', params: { id: active.id }, query: { actAs: $route.query.actAs } }).href"
                            target="_blank"
                          >
                            <i
                              class="me-50"
                              data-feather="user-check"
                            />
                            View evaluators groups
                          </b-dropdown-item>
                          <b-dropdown-item
                            :href="$router.resolve({ name: 'admin.calls.promotion.pending', params: { id: active.id }, query: { actAs: $route.query.actAs } }).href"
                            target="_blank"
                          >
                            <i
                              class="me-50"
                              data-feather="user-x"
                            /> Pending
                            evaluators
                          </b-dropdown-item>
                          <b-dropdown-item>
                            <i
                              class="me-50"
                              data-feather="award"
                            /> View
                            ranking
                          </b-dropdown-item>
                          <div class="dropdown-divider" />
                          <b-dropdown-item
                            :href="
                              $router.resolve({
                                name: 'admin.calls.promotion.edit',
                                params: { id: active.id },
                              }).href
                            "
                            target="_blank"
                          >
                            <i
                              class="me-50"
                              data-feather="edit-3"
                            /> Edit
                          </b-dropdown-item>
                          <b-dropdown-item @click="duplicateConvo(active.id)">
                            <i
                              class="me-50"
                              data-feather="copy"
                            />
                            Duplicate
                          </b-dropdown-item>
                          <b-dropdown-item>
                            <i
                              class="me-50"
                              data-feather="database"
                            />
                            Logs
                          </b-dropdown-item>
                          <div class="dropdown-divider" />
                          <b-dropdown-item>
                            <i
                              class="me-50"
                              data-feather="bell"
                            />
                            Notifications
                          </b-dropdown-item>
                          <div class="dropdown-divider" />
                          <b-dropdown-item>
                            <i
                              class="me-50"
                              data-feather="trash-2"
                            /> Delete
                          </b-dropdown-item>
                        </div>
                      </b-dropdown>
                      <a
                        href=""
                        class="btn btn-icon btn-light-secondary d-block d-sm-none"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasMenu"
                        aria-controls="offcanvasBottom"
                      ><i
                        data-feather="more-vertical"
                      /></a>
                    </div>
                    <div class="col-10 col-sm-10">
                      <h1>
                        <small>Active call</small> {{ active.title }}
                        <span>
                          <template v-for="(area, index) in active.areas">
                            {{ !area.parent_id ? (area.code + (index < active.areas.length - 1 ? ", " : " ")) : null }}
                          </template>
                        </span>
                      </h1>
                    </div>
                  </div>
                </div>
                <div class="card--senior-call-section">
                  <strong>{{ active.stats.researchers }}
                    <a
                      class="btn btn-icon btn-flat-secondary"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasApplicants"
                      aria-controls="offcanvasApplicants"
                    ><i data-feather="eye" /></a></strong>Researchers
                </div>
                <div class="card--senior-call-section">
                  <strong>{{ active.stats.pc_members }}
                    <a
                      href=""
                      class="btn btn-icon btn-flat-secondary"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasEvaluators"
                      aria-controls="offcanvasEvaluators"
                    ><i data-feather="eye" /></a></strong>PC Members
                </div>
                <div class="card--senior-call-section">
                  <strong>{{ active.stats.peers }}
                    <a
                      href=""
                      class="btn btn-icon btn-flat-secondary"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasFeedbackEditors"
                      aria-controls="offcanvasFeedbackEditors"
                    ><i data-feather="eye" /></a></strong>Peers
                </div>
                <div class="card--senior-call-section">
                  <strong>{{ active.stats.hosts }}
                    <a
                      href=""
                      class="btn btn-icon btn-flat-secondary"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasFeedbackEditors"
                      aria-controls="offcanvasFeedbackEditors"
                    ><i data-feather="eye" /></a></strong>Hosts
                </div>
              </div>
            </div>
            <CompleteTable
              :sorted-field="sortedField"
              :data="convos"
              :sort-mapping="sortMapping"
              :title="'archived promotion calls'"
              :total="totalConvos"
              :fetch-path="'convo/fetchAll'"
              :export-path="'convo/export'"
              :repeaters-view="true"
              :filter-path="'convo/fetchPromotion'"
            >
              <template v-slot="{field}">
                <td
                  class="text-end"
                >
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div
                      class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                    >
                      <b-dropdown-item
                        :href="$router.resolve({ name: 'admin.calls.promotion.view', params: { id: field.id }, query: { actAs: $route.query.actAs } }).href"
                        target="_blank"
                      >
                        <i
                          class="me-50"
                          data-feather="eye"
                        /> View candidates
                      </b-dropdown-item>
                      <b-dropdown-item
                        href="https://grafana.studiogenesis.dev/d/b8d5dd37-c6b5-4d0a-9ab8-8a05ed082cf4/promotion-1-icreas-a-promocionar?orgId=1&from=1716781082944&to=1716802682944"
                        target="_blank"
                      >
                        <i
                          class="me-50"
                          data-feather="file"
                        />
                        Download candidates
                      </b-dropdown-item>
                      <div class="dropdown-divider" />
                      <b-dropdown-item
                        :href="
                          $router.resolve({
                            name: 'admin.calls.promotion.edit',
                            params: { id: field.id },
                          }).href
                        "
                        target="_blank"
                      >
                        <i
                          class="me-50"
                          data-feather="edit-3"
                        /> Edit
                      </b-dropdown-item>
                      <b-dropdown-item @click="duplicateConvo(field.id)">
                        <i
                          class="me-50"
                          data-feather="copy"
                        />
                        Duplicate
                      </b-dropdown-item>
                      <!-- <div class="dropdown-divider" />
                      <b-dropdown-item>
                        <i
                          class="me-50"
                          data-feather="bell"
                        />
                        Notifications
                      </b-dropdown-item> -->
                      <div class="dropdown-divider" />
                      <b-dropdown-item>
                        <i
                          class="me-50"
                          data-feather="trash-2"
                        /> Delete
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                  <a
                    href=""
                    class="btn btn-icon btn-light-secondary d-block d-sm-none"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasMenu"
                    aria-controls="offcanvasBottom"
                  ><i
                    data-feather="more-vertical"
                  /></a>
                </td>
              </template>
              <template #customfilters>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Candidate</label>
                  <input
                    v-model="filters.candidate"
                    type="text"
                    class="form-control"
                    name="nameseniorcall"
                    @change="saveFilter({ customLabel: true, name: `Candidate: ${$event.target.value}`, value: `${$event.target.value}` }, 'candidate')"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for="areaseniorcall"
                    class="form-label"
                  >Area</label>
                  <v-select
                    v-if="areas.length !== undefined"
                    v-model="filters.areas"
                    multiple
                    label="name"
                    :options="areas"
                    :get-option-key="option => option.id"
                    @input="saveFilter({ customLabel: true, name: `Areas: ${$event ? ($event.map(e => e.name)).join(' - ') : ''}`, value: $event }, 'areas')"
                  >
                    <option
                      value=""
                      selected
                    >
                      All areas
                    </option>
                  </v-select>
                </div>
              </template>
            </CompleteTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import vSelect from 'vue-select'
import CompleteTable from '../../partials/components/CompleteTable.vue'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    CompleteTable,
    vSelect,
  },
  data() {
    return {
      sortedField: 'Title',
      defaultFields: [
        {
          name: 'Title',
          checked: true,
          order: 1,
        },
        {
          name: 'Year',
          checked: true,
          order: 2,
        },
        {
          name: 'Acronym',
          checked: true,
          order: 3,
        },
        {
          name: 'Status',
          checked: true,
          order: 4,
        },
        {
          name: 'Applicants',
          checked: true,
          order: 5,
        },
        {
          name: 'Evaluators',
          checked: true,
          order: 6,
        },
        {
          name: 'Referees',
          checked: true,
          order: 7,
        },
        {
          name: 'Pending applications',
          checked: true,
          order: 8,
        },
        {
          name: 'Hosts',
          checked: true,
          order: 9,
        },
        {
          name: 'Areas call',
          checked: true,
          order: 10,
        },
      ],
      sortMapping: {
        Title: 'title',
        Acronym: 'code',
        Year: 'year',
        Status: 'status',
        Applicants: 'stats.applicants',
        Evaluators: 'evaluators.length',
        Referees: '',
        'Pending applications': '',
        Hosts: '',
        'Areas call': 'areas',
      },
    }
  },
  computed: {
    ...mapGetters({
      convos: 'convo/convos',
      areas: 'areas/areas',
      active: 'convo/convo',
      filters: 'filters/filters',
      fields: 'modals/tableSettingsFields',
      totalConvos: 'convo/totalConvos',
    }),
  },
  async mounted() {
    this.fetchConvos()
    if (this.areas.length === 0) {
      this.$store.dispatch('areas/filter', this.$store.getters['filters/filters'])
    }
    await this.$store.dispatch('modals/fetchUserFields', 'convos')
    if (!this.fields || this.fields.length === undefined) {
      // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'convos',
      })
    }
  },
  methods: {
    async fetchConvos() {
      await this.$store.dispatch('convo/fetchPromotion', this.$store.getters['filters/filters'])
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    },
    async exportConvos() {
      await this.$store.dispatch('convo/export')
    },
    saveFilter(value, field) {
      if (!value || (!value.value || (value.value === 'null' || (Array.isArray(value.value) && !value.value.length)))) {
        this.$store.dispatch('filters/deleteFilters', field)
      } else {
        this.$store.dispatch('filters/saveFilters', {
          value,
          field,
        })
      }
    },
    async duplicateConvo(id) {
      await this.$store.dispatch('convo/duplicate', id)
      await this.$store.dispatch('convo/fetchAll', 'promotion')
    },
  },
}
</script>
